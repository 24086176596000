@import url("https://fonts.googleapis.com/css?family=Audiowide");
@import url("https://fonts.googleapis.com/css2?family=Yantramanav:wght@700&display=swap");

body {
  background-color: #282d35;
}

.profile-title {
  align-items: left;
  color: #deebf8;
}

.center-text {
  color: #deebf8;
  margin: 1.5px;
  font-family: "Yantramanav", sans-serif;
  font-size: 17px;
}

.container {
  justify-content: center;
  align-items: center;
  height: 90vh;
  display: flex;
}

.big-box {
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
  width: 40%;
  padding-bottom: 40px;
  background: #21222a;
  min-height: fit-content;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 50%;
  padding: 20px;
}

.user-picture {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 20px;
}

.heading {
  align-items: center;
  color: #deebf8;
  font-size: 40px;
  font-family: "Audiowide", sans-serif;
}

.button {
  background-color: #5ce1e6;
  color: #282d35;
  border-color: transparent;
  font-family: "Yantramanav", sans-serif;
  font-size: 16px;
}

.hidden {
  visibility: hidden;
}


.arrow-button {
  font-size: 17.5px;
}

@media (max-width: 599px) {
  .arrow-button {
    font-size: 10px;
    width: 40px;
    height: 40px;
    margin-top: 8px;
  }
}

.add-card-button {
  font-size: 17.5px;
}

@media (max-width: 599px) {
  .add-card-button {
    font-size: 10px;
    width: 40px;
    height: 40px;
    margin-top: 8px;
  }
}