@import url("https://fonts.googleapis.com/css?family=Audiowide");
@import url("https://fonts.googleapis.com/css2?family=Yantramanav:wght@700&display=swap");

.logo {
  width: 215px;
  height: auto;
}

@media (max-width: 600px) {
  .logo {
    width: 140px;
    height: auto;
  }
}

.buttonNav {
  background-color: #004aad;
  color: #e6e6e6;
  border-color: transparent;
  font-family: "Yantramanav", sans-serif;
  font-size: 16px;
}
