@import url("https://fonts.googleapis.com/css?family=Audiowide");
@import url("https://fonts.googleapis.com/css2?family=Yantramanav:wght@700&display=swap");

.heading {
    align-items: center;
}

@media (max-width: 699px) {
    .heading{
        font-size: 30px;
        align-items: center;
    }
}

@media (max-width: 499px) {
    .heading {
        font-size: 19px;
        align-items: center;

    }
}



@media (max-width: 699px) {
    .subheading {
        font-size: 22px;
        align-items: center;
    }
}

@media (max-width: 499px) {
    .subheading {
        font-size: 16px;
        align-items: center;

    }
}