.root {
    padding: 80px;
}

@media (max-width: 599px) {
    .root {
        padding: 40px;
    }
}

.deckColumn {
    border: 1px solid #ccc;
    padding: 20px;
    color: #deebf8;
    border-radius: 10px;
}

.deckTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
    cursor: pointer;
}

.deckTitle:hover {
    color: #b5c5e5;
    text-decoration: underline;
    
}

@media (max-width: 599px) {
    .deckTitle {
    font-size: 20px;
        font-weight: bold;
    }
}

@media only screen and (min-width: 699px) and (max-width: 960px) {
    .deckColumn {
      
        /* adjust the value based on your design */
        margin: 0 auto;
        /* center the column */
        padding: 1rem;
        /* add some padding to the column */
    }
}

.circular {
    font-size: 22px;
    font-weight: bold;
}

@media (max-width: 599px) {
    .circular {
        font-size: 18px;
        font-weight: bold;
    }
}

